import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import Button from 'components/Common/Button';
import logo from 'static/icons/inatur_logo.svg';
import { useOidc } from '../../oidc';

const style = (theme) => ({
  container: {
    position: 'absolute',
    height: '100%',
  },
  left: {
    background: 'rgba(253, 252, 249, 1)',
  },
  right: {
    background: theme.gradients.light,
  },
  content: {
    padding: '200px 80px 80px 80px',
    color: theme.colors.fontColor1,
  },
  divider: {
    border: 'none',
    borderBottom: `1px solid ${theme.colors.fontColor1}`,
    marginBottom: '2rem',
  },
  title: {
    marginBottom: theme.margins.small,
    fontFamily: theme.fonts.vollkorn,
    color: theme.colors.fontColor1,
    fontWeight: 'normal',
    fontSize: 24,
  },
  logo: {
    height: 40,
    width: 110,
    position: 'absolute',
    right: 30,
    top: 200 + 2 * theme.custom.cardHeightLarge,
  },
  loginExplainer: {
    ...theme.typography.subheading,
    fontFamily: theme.fonts.barlow,
  },
});

const Login = ({ classes }) => {
  const { login } = useOidc();

  return (
    <Grid container spacing={0} className={classes.container}>
      <Grid item xs={12} sm={7} md={7} lg={7} className={classes.left}>
        <div className={classes.content}>
          <Typography variant="display1" gutterBottom className={classes.title}>
            INATUR FORHANDLERLØSNING
          </Typography>
          <hr className={classes.divider} />

          <p className={classes.loginExplainer}>
            For å logge på forhandlerløsningen trykker du på knappen nedenfor og fyller ut detaljene
            dine hos Inaturs sentraliserte påloggingstjeneste.
          </p>

          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Button
              className={classes.button}
              theme="secondary"
              onClick={() => login({ doesCurrentHrefRequiresAuth: false, redirectUrl: '/' })}
              style={{ marginTop: '1rem' }}
              label="Logg på med Inatur">
              Logg på med Inatur
            </Button>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12} sm={5} md={5} lg={5} className={classes.right}>
        <div>
          <img className={classes.logo} src={logo} alt="logo" />
        </div>
      </Grid>
    </Grid>
  );
};

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Login);
