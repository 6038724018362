import React from 'react';
import { Route, Redirect, withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ErrorBoundary from 'components/Error/ErrorBoundary';

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { isAuthenticated, component, render, ...rest } = this.props;

    if (isAuthenticated) {
      return component ? (
        <ErrorBoundary>
          <Route {...rest} component={component} />
        </ErrorBoundary>
      ) : (
        <ErrorBoundary>
          <Route {...rest} render={render} />
        </ErrorBoundary>
      );
    } else {
      return <Redirect to="/login" />;
    }
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
  render: PropTypes.func,
  isAuthenticated: PropTypes.bool.isRequired,
};

PrivateRoute.defaultProps = {
  component: null,
  render: null,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.get.auth.isAuthenticated,
});

export default withRouter(connect(mapStateToProps, {})(PrivateRoute));
