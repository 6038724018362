import { getRequest } from 'store/actions/request';
import { getOidc } from '../../oidc';

export const INITIALISE_AUTH_SUCCESS = 'INITIALISE_AUTH_SUCCESS';
export const INITIALISE_AUTH_FAILURE = 'INITIALISE_AUTH_FAILURE';

function initialiseAuthSuccess(data) {
  return {
    type: INITIALISE_AUTH_SUCCESS,
    user: data,
  };
}

function initialiseAuthFailure() {
  return {
    type: INITIALISE_AUTH_FAILURE,
  };
}

export function initialiseAuth() {
  return async (dispatch) => {
    const oidc = await getOidc();
    if (!oidc.isUserLoggedIn) {
      return dispatch(initialiseAuthFailure());
    }

    const { status, json } = await getRequest('/api/v1/forhandlere/user');

    if (status === 200) {
      return dispatch(initialiseAuthSuccess(json));
    } else {
      return dispatch(initialiseAuthFailure());
    }
  };
}

export function logout() {
  return async () => {
    const oidc = await getOidc();
    await oidc.logout({ redirectTo: 'specific url', url: '/login' });
  };
}
