import 'whatwg-fetch';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import CssBaseline from '@material-ui/core/CssBaseline';

import { configureStore, saveState, loadState } from 'store';
import { throttle } from 'lodash';
import App from 'containers/AppContainer';
import ErrorBoundary from 'components/Error/ErrorBoundary';

import { theme } from 'styles';
import 'toastr/build/toastr.css';
import 'styles/index.css';
import { initialiseAuth } from 'store/actions/auth';
import { OidcProvider } from './oidc';

export const history = createBrowserHistory({ basename: '/' });
const persistedState = loadState();
export const store = configureStore(history, persistedState);

async function initialiseApplication() {
  await store.dispatch(initialiseAuth());

  store.subscribe(
    throttle(() => {
      saveState({
        rootReducer: {
          orderReducer: store.getState().rootReducer.orderReducer,
        },
      });
    }, 1001)
  );

  render(
    <Provider store={store}>
      <OidcProvider>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <ErrorBoundary>
            <App history={history} />
          </ErrorBoundary>
        </MuiThemeProvider>
      </OidcProvider>
    </Provider>,
    document.getElementById('forhandler-container')
  );
}

initialiseApplication();
