import { INITIALISE_AUTH_SUCCESS, INITIALISE_AUTH_FAILURE } from 'store/actions/auth';

const initialState = {
  user: null,
  isAuthenticated: false,
};
// eslint-disable-next-line default-param-last
export default function auth(state = initialState, action) {
  switch (action.type) {
    case INITIALISE_AUTH_SUCCESS:
      return {
        ...state,
        user: action.user,
        isAuthenticated: true,
      };

    case INITIALISE_AUTH_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
      };

    default:
      return state;
  }
}
