import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Login from 'components/Login';

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.history.push('/');
    }
  }

  render() {
    return (
      <div className="slide-out">
        <Login />
      </div>
    );
  }
}

LoginContainer.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.get.auth.isAuthenticated,
});

export default connect(mapStateToProps, {})(LoginContainer);
